angular.module('adminApp').controller("manageGroupsCtrl", ["$scope", "$uibModal", "Notification", "aerosAdminApi", "viewUsersInGroupModal", function ($scope, $uibModal, Notification, aerosAdminApi, viewUsersInGroupModal) {

	if (!$scope.allow['editAdminGroups']) return $scope.reject();
	
    // init
    init();

    function init() {
        console.log("init manage groups.");
        aerosAdminApi.getAdminGroups()
            .success(function (data) {
                $scope.adminGroups = data.groups;
            })
            .error(function (err) {
                Notification.error("Something went wrong. " + err.message);
            });
    }

    $scope.viewListOfUsersInRole = function(group) {
        console.log("Pop up list of users in this group");
        //TODO: Need to create the popup modal.
        viewUsersInGroupModal.open(group).then(function(){
            init;
        })
    }
}]);
